import { useContext } from 'react';
import { Typography } from '@mui/material';
import './Licenses.css';
import DataContext from '../../services/DataContext';
import LicensesTable from './LicensesTable';
import DeleteLicense from './DeleteLicense';
import AddLicense from './AddLicense';
import useLicenseState from '../Hooks/useLicenseState';
import { License } from '../../types/licenseTypes';
import EditLicense from './EditLicense';
import BulkDeleteLicenses from './BulkDeleteLicenses';

const Licenses = () => {
  const { deleteItem } = useContext(DataContext);
  const {
    searchValue,
    selectedLicense,
    confirmDialogOpen,
    addDialogOpen,
    licenseChangeIndicator,
    handleSearchChange,
    handleDeleteLicense,
    handleOpenAddDialog,
    handleCloseAddDialog,
    setConfirmDialogOpen,
    setLicenseChangeIndicator,
    editDialogOpen,
    handleOpenEditDialog,
    handleCloseEditDialog,
    handleSelectionModelChange,
    selectionModel,
    handleOpenBulkDeleteDialog,
    bulkDeleteDialogOpen,
    handleCloseBulkDeleteDialog,
    selectedLicensesForDeletion,
    clearSelectedLicensesForDeletion,
  } = useLicenseState();

  return (
    <>
      <Typography
        variant="h1"
        component="h1"
        sx={{
          color: 'var(--off-white-color)',
          textAlign: 'center',
          mb: 6,
        }}
      >
        Liste des licences
      </Typography>

      <LicensesTable
        handleDeleteLicense={handleDeleteLicense}
        handleOpenAddDialog={handleOpenAddDialog}
        handleOpenEditDialog={handleOpenEditDialog}
        handleSearchChange={handleSearchChange}
        handleOpenBulkDeleteDialog={handleOpenBulkDeleteDialog}
        searchValue={searchValue}
        licenseChangeIndicator={licenseChangeIndicator}
        selectionModel={selectionModel}
        handleSelectionModelChange={handleSelectionModelChange}
        selectedLicensesForDeletion={selectedLicensesForDeletion}
      />

      <DeleteLicense
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        license={selectedLicense as License}
        deleteItem={deleteItem}
        onLicenseChange={() =>
          setLicenseChangeIndicator(!licenseChangeIndicator)
        }
      />

      <AddLicense
        open={addDialogOpen}
        onClose={handleCloseAddDialog}
        onLicenseChange={() =>
          setLicenseChangeIndicator(!licenseChangeIndicator)
        }
      />

      <EditLicense
        open={editDialogOpen}
        onClose={handleCloseEditDialog}
        license={selectedLicense as License}
        onLicenseChange={() =>
          setLicenseChangeIndicator(!licenseChangeIndicator)
        }
      />

      <BulkDeleteLicenses
        open={bulkDeleteDialogOpen}
        onClose={handleCloseBulkDeleteDialog}
        licenses={selectedLicensesForDeletion}
        deleteItem={deleteItem}
        clearSelectedLicenses={clearSelectedLicensesForDeletion}
        onLicenseChange={() =>
          setLicenseChangeIndicator(!licenseChangeIndicator)
        }
      />
    </>
  );
};

export default Licenses;

import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#a0c51b',
      dark: '#7d9e15',
    },
    secondary: {
      main: '#1a3c90',
      dark: '#0e2356',
    },
    background: {
      default: '#000000',
      paper: '#121212',
    },
    text: {
      primary: '#eee5e9',
      secondary: '#a0c51b',
    },
    error: {
      main: '#ff1744',
    },
    warning: {
      main: '#ff9100',
    },
    info: {
      main: '#2962ff',
    },
    success: {
      main: '#64dd17',
    },
  },

  typography: {
    htmlFontSize: 10,
    fontSize: 16,
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2.5rem',
    },
    h3: {
      fontSize: '2rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.2rem',
    },
    h6: {
      fontSize: '1.6rem',
    },
    a: {
      textDecoration: 'none',
    },
  },

  components: {
    MuiContainer: {
      styleOverrides: {
        root: {},
      },
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          backgroundColor: 'transparent',
          paddingBlock: '1rem',
        },
      },
    },

    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: '#121212',
          left: '20%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            borderRadius: '0.3rem',
          },
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          fontSize: '1.2rem',
          paddingLeft: '0',
          paddingRight: '0',
          '@media (min-width: 600px)': {
            paddingLeft: '0',
            paddingRight: '0',
          },
        },
      },
    },

    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
        },
        columnHeaderTitle: {
          fontWeight: '600',
          fontSize: '1.3rem',
          textTransform: 'uppercase',
          letterSpacing: '0.05rem',
        },
        overlayWrapper: {
          minHeight: '150px',
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '1.4rem',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset legend': {
            fontSize: '1rem',
          },
        },
      },
    },

    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          fontSize: '1.3rem',
        },
        selectLabel: {
          fontSize: '1.3rem',
        },
        displayedRows: {
          fontSize: '1.3rem',
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: '#121212',
          color: '#eee5e9',
          fontWeight: '600',
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundColor: '#121212',
          overflowY: 'none',
          scrollbarWidth: 'none',
          '-ms-overflow-style': 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          color: '#eee5e9',
          '& input': { fontSize: '1.4rem' },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '1.4rem',
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: '#1d1c1c',
          paddingInline: '2.4rem',
          '& .MuiButton-root': {
            fontSize: '1.4rem',
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1.4rem',
          backgroundColor: '#1d1c1c',
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          marginBlock: '1.6rem',
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '1.2rem',
          color: '#6f706e',
          lineHeight: 1.3,
          marginTop: '1rem',
        },
      },
    },
  },
});

import React, {
  createContext,
  useCallback,
  useMemo,
  useContext,
  useEffect,
} from 'react';
import axios from 'axios';
import { AuthContext, User } from './AuthContext';

export interface CreateLicenseData {
  clientNumber: string;
  cadworkDisplay: string;
  cadworkId: string;
  softwareId: string;
  code: string;
  startDate: Date;
  endDate: Date;
  type: string;
  createdBy?: string;
}

type ResetPasswordParams = {
  type: 'init' | 'finish';
  email?: string;
  token?: string | null;
  newPassword?: string | null;
};

export interface IDataContext {
  loginUser: (email: string, password: string) => Promise<boolean>;
  resetPassword: (params: ResetPasswordParams) => Promise<void>;
  getProfile: () => Promise<User>;
  updateUser: (userId: string, updateData: Partial<User>) => Promise<void>;
  getItem: <T>(endpoint: string, identifier: string) => Promise<T>;
  getAll: (
    endpoint: string,
    limit: number,
    skip: number,
    searchValue: string,
    orderField: string,
  ) => Promise<any>;
  getCount: (endpoint: string) => Promise<number>;
  getCountForPagination: (
    endpoint: string,
    searchValue: string,
  ) => Promise<number>;
  deleteItem: (
    endpoint: string,
    identifier: string,
    email?: string,
  ) => Promise<void>;
  createLicense: (license: CreateLicenseData, email?: string) => Promise<void>;
  setUser: (user: User | null) => void;
}

export const DataContext = createContext<IDataContext>({
  loginUser: async () => false,
  resetPassword: async () => {},
  getProfile: async () => {
    return {
      id: '',
      email: '',
      firstName: '',
      lastName: '',
      roleIds: [],
    };
  },
  updateUser: async () => {},
  getCountForPagination: async () => 0,
  getItem: async (endpoint: string, identifier: string) => {
    return Promise.reject();
  },
  getAll: async () => {},
  getCount: async () => 0,
  deleteItem: async () => {},
  createLicense: async () => {},
  setUser: () => {},
});

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isLoggedIn, setToken, logout, setUser } = useContext(AuthContext);
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/`,
    timeout: 3000,
    headers: {
      Authorization: `Bearer ${
        isLoggedIn ? localStorage.getItem('token') : ''
      }`,
      'Access-Control-Expose-Headers': 'X-Total-Count',
    },
  });

  instance.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  });

  useEffect(() => {
    instance.interceptors.response.use(
      response => response,
      error => {
        try {
          if (error.response) {
            if (error.response.status === 401) {
              logout();
            }
          } else if (error.request) {
            throw new Error('No response received from server.');
          }
          return Promise.reject(error);
        } catch (error) {
          return Promise.reject(error);
        }
      },
    );
  }, [instance, logout]);

  const loginUser = useCallback(
    async (email: string, password: string) => {
      const credentials = {
        email: email,
        password: password,
      };

      try {
        const response = await instance.post('users/login', credentials);

        if (response.status === 200) {
          const { token, user } = response.data;
          setToken(token);
          setUser(user);

          return true;
        }

        return false;
      } catch (error) {
        throw error;
      }
    },
    [instance, setToken, setUser],
  );

  const getCountForPagination = useCallback(
    async (endpoint: string, searchValue: string) => {
      const response = await instance.get(`api/${endpoint}/?q=${searchValue}`);

      if (response.status !== 200) {
        throw new Error(`Failed to fetch ${endpoint} count`);
      }

      return response.data.length;
    },
    [instance],
  );

  const resetPassword = useCallback(
    async ({
      type,
      email,
      token,
      newPassword,
    }: ResetPasswordParams): Promise<void> => {
      try {
        const endpoint = `reset-password/${type}`;
        let payload: any = {};
        let method: 'post' | 'put' = 'post';

        if (type === 'init') {
          if (!email) {
            throw new Error('Email is required for init type');
          }
          payload.email = email;
        } else if (type === 'finish') {
          if (!token || !newPassword) {
            throw new Error(
              'Token and new password are required for finish type',
            );
          }
          payload = {
            resetPassword: {
              key: token,
            },
            password: newPassword,
            confirmPassword: newPassword,
          };
          method = 'put';
        }

        if (method === 'post') {
          await instance.post(endpoint, payload);
        } else if (method === 'put') {
          await instance.put(endpoint, payload);
        }
      } catch (error) {
        throw error;
      }
    },
    [instance],
  );

  const getProfile = useCallback(async (): Promise<User> => {
    try {
      const response = await instance.get('whoAmI');
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to fetch profile');
      }
    } catch (error) {
      throw error;
    }
  }, [instance]);

  const updateUser = useCallback(
    async (userId: string, updateData: Partial<User>) => {
      try {
        await instance.patch(`users/${userId}`, updateData);
      } catch (error) {
        throw error;
      }
    },
    [instance],
  );

  const getItem = useCallback(
    async (endpoint: string, identifier: string) => {
      try {
        const response = await instance.get(`api/${endpoint}/${identifier}`);
        return response.data;
      } catch (error) {
        throw new Error(`Failed to fetch ${endpoint} with id '${identifier}'`);
      }
    },
    [instance],
  );

  const getAll = useCallback(
    async (
      endpoint: string,
      limit: number = 1000,
      skip: number = 0,
      searchValue: string = '',
      orderField: string = '',
    ) => {
      let url = `api/${endpoint}?filter[limit]=${limit}&filter[skip]=${skip}`;
      if (orderField !== '') {
        url += `&filter[order]=${orderField}%20DESC`;
      }
      if (searchValue !== '') {
        url += `&q=${searchValue}`;
      }
      const response = await instance.get(url);
      const totalCount = +response.headers['x-total-count'];

      return { data: response.data, totalCount };
    },
    [instance],
  );

  const getCount = useCallback(
    async (endpoint: string) => {
      const response = await instance.get(`/api/${endpoint}/count`);

      if (response.status !== 200) {
        throw new Error('Failed to fetch count');
      }

      return response.data.count;
    },
    [instance],
  );

  const deleteItem = useCallback(
    async (
      endpoint: string,
      identifier: string,
      email?: string,
    ): Promise<void> => {
      try {
        const params = email ? { email } : {};
        await instance.delete(`api/${endpoint}/${identifier}`, { params });
      } catch (error) {
        throw new Error(`Failed to delete item, ${error}`);
      }
    },
    [instance],
  );

  const createLicense = useCallback(
    async (license: CreateLicenseData, email?: string): Promise<void> => {
      try {
        const data = { ...license };
        let config = {};
        if (email) {
          config = {
            params: {
              email: email,
            },
          };
        }
        await instance.post('api/licenses', data, config);
      } catch (error) {
        throw new Error(`Failed to create license, ${error}`);
      }
    },
    [instance],
  );

  const value = useMemo(
    () => ({
      loginUser,
      resetPassword,
      getProfile,
      updateUser,
      getItem,
      getCountForPagination,
      getAll,
      getCount,
      deleteItem,
      createLicense,
      setUser,
    }),
    [
      loginUser,
      resetPassword,
      getProfile,
      updateUser,
      getCountForPagination,
      getItem,
      getAll,
      getCount,
      deleteItem,
      createLicense,
      setUser,
    ],
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export default DataContext;

import React, { Fragment } from 'react';
import { Divider, Link, Box } from '@mui/material';
import dayjs from 'dayjs';
import GenericDialog from '../uiTools/Dialog/GenericDialog';
import { EmailHistoryTypes } from '../../types/emailHistoryTypes';

interface SingleEmailProps {
  selectedRow: EmailHistoryTypes | null;
  handleClose: () => void;
}

interface DateSectionProps {
  title: string;
  date: Date | string;
}

const EmailSection: React.FC<{ email: string }> = ({ email }) => {
  return (
    <Link href={`mailto:${email.toLowerCase()}`}>{email.toLowerCase()}</Link>
  );
};

const DateSection: React.FC<DateSectionProps> = ({ title, date }) => {
  const formattedDate = dayjs(date.toString()).format('DD/MM/YYYY');

  return (
    <p>
      <strong>{title}</strong> : {formattedDate}
    </p>
  );
};

const SingleEmail: React.FC<SingleEmailProps> = ({
  selectedRow,
  handleClose,
}) => {
  return selectedRow ? (
    <GenericDialog
      open={!!selectedRow}
      onClose={handleClose}
      title={`Emails envoyés le ${selectedRow.sendAt} (${selectedRow.triggerEvent})`}
      onCancel={handleClose}
      confirmButtonText="Fermer"
      onConfirm={handleClose}
      showCancel={false}
    >
      <p>
        De : <EmailSection email={selectedRow.senderEmail} />
      </p>
      <Divider />
      <p>
        À :{' '}
        {selectedRow.recipientEmail
          .toLowerCase()
          .split(', ')
          .map((email, index, array) => (
            <Fragment key={email + index}>
              <EmailSection email={email} />
              {index < array.length - 1 && ', '}
            </Fragment>
          ))}
      </p>
      <Divider />
      <p>Sujet : {selectedRow.subject}</p>
      <Divider />
      <p style={{ overflowWrap: 'break-word' }}>
        Email envoyé à <EmailSection email={selectedRow.cwUserMail} /> :
        <br />
        {selectedRow.body.client}
      </p>
      <Divider />
      <p style={{ overflowWrap: 'break-word' }}>
        Email envoyé à <EmailSection email={selectedRow.cwSellerMail} /> :
        <br />
        {selectedRow.body.seller}
      </p>
      <Divider />
      <Box sx={{ fontSize: '1.4rem' }}>
        <p>
          <strong>Application</strong> : {selectedRow.cwApp}
        </p>
        <p>
          <strong>Première demande de licence</strong> :{' '}
          {selectedRow.cwTrialOK === 1 ? 'oui' : 'non'}
        </p>
        <DateSection
          title="Début de la licence"
          date={selectedRow.cwTrialFrom}
        />
        <DateSection title="Fin de la licence" date={selectedRow.cwTrialTo} />
        <strong>Type de licence</strong> : {selectedRow.cwTrialType}
        <p>
          <strong>User infos</strong> : {selectedRow.cwUserName},{' '}
          {selectedRow.cwUserPhone}, {selectedRow.cwUserMail},{' '}
          {selectedRow.cwUserId}, {selectedRow.cwUserKeyId},{' '}
          {selectedRow.cwUserCompany},{' '}
          {selectedRow.cwUserLanguage.toUpperCase()}
        </p>
        <p>
          <strong>Commentaire</strong> :{' '}
          {selectedRow.cwUserComments !== ''
            ? selectedRow.cwUserComments
            : 'non'}
        </p>
        <Box component="p" display="inline">
          <strong>Code</strong>&nbsp;:&nbsp;
          <Box
            component="span"
            display="inline"
            style={{ overflowWrap: 'break-word' }}
          >
            {selectedRow.cwTrialCode}
          </Box>
        </Box>
      </Box>
    </GenericDialog>
  ) : null;
};

export default SingleEmail;

import { useCallback, useEffect, useState } from 'react';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
} from '@mui/x-data-grid';
import { Box, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { GridActionsCellItem } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import CustomToolbar from './CustomToolbar';
import SnackBar from '../../SnackBar/SnackBar';
import { License, LicenseArchive } from '../../../types/licenseTypes';
import EditIcon from '@mui/icons-material/EditOutlined';

dayjs.locale('fr');

interface GenericLicensesTableProps {
  handleOpenEditDialog?: (license: License | LicenseArchive) => void;
  handleDeleteLicense?: (license: License | LicenseArchive) => void;
  handleOpenAddDialog?: () => void;
  handleSearchChange: (newSearch: React.ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
  licenseChangeIndicator: boolean;
  isArchive: boolean;
  licenses: Array<License | LicenseArchive>;
  rowCount: number;
  paginationModel: GridPaginationModel;
  onPaginationModelChange: (model: GridPaginationModel) => void;
  selectionModel?: (string | number)[];
  handleSelectionModelChange?: (
    newSelection: (string | number)[],
    licenses: License[],
  ) => void;
  handleOpenBulkDeleteDialog?: (
    selectedLicenses: (License | LicenseArchive)[],
  ) => void;
  handleAddLicenseForDeletion?: (license: License | LicenseArchive) => void;
  selectedLicensesForDeletion?: (License | LicenseArchive)[];
}

const GenericLicensesTable: React.FC<GenericLicensesTableProps> = ({
  handleOpenEditDialog,
  handleDeleteLicense,
  handleOpenAddDialog,
  handleSearchChange,
  searchValue,
  licenseChangeIndicator,
  isArchive,
  licenses,
  rowCount,
  paginationModel,
  onPaginationModelChange,
  selectionModel,
  handleSelectionModelChange,
  handleOpenBulkDeleteDialog,
  handleAddLicenseForDeletion,
  selectedLicensesForDeletion,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pagination, _setPagination] = useState({
    page: 0,
    pageSize: 10,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState<{ message: string | null; open: boolean }>(
    {
      message: null,
      open: false,
    },
  );

  const CustomToolbarWrapper = useCallback(() => {
    return (
      <CustomToolbar
        searchValue={searchValue}
        handleSearchChange={handleSearchChange}
        handleOpenAddDialog={isArchive ? undefined : handleOpenAddDialog}
        handleOpenBulkDeleteDialog={
          handleOpenBulkDeleteDialog ? handleOpenBulkDeleteDialog : () => {}
        }
        selectionModel={selectionModel}
        selectedLicensesForDeletion={selectedLicensesForDeletion || []}
      />
    );
  }, [
    selectionModel,
    searchValue,
    handleSearchChange,
    handleOpenBulkDeleteDialog,
    handleOpenAddDialog,
    isArchive,
    selectedLicensesForDeletion,
  ]);

  const baseColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'clientNumber',
      headerName: 'N° de client',
      width: 200,
    },
    { field: 'cadworkDisplay', headerName: 'cadwork Display', width: 200 },
    { field: 'cadworkId', headerName: 'ID cadwork', width: 200 },
    { field: 'softwareId', headerName: 'Logiciel', width: 200 },
    { field: 'code', headerName: 'Code', width: 200 },
    { field: 'startDate', headerName: 'Début', width: 200 },
    { field: 'endDate', headerName: 'Fin', width: 200 },
    { field: 'type', headerName: 'Type', width: 200 },
    {
      field: 'totalAccess',
      headerName: 'Accès',
      width: 200,
      valueGetter: params =>
        `${params.value} ${
          params.row.readedAt ? `(${params.row.readedAt})` : ''
        }`,
    },
    {
      field: 'createdAt',
      headerName: 'Création',
      width: 200,
      valueGetter: params =>
        `${params.value} ${
          params.row.createdBy ? `(${params.row.createdBy})` : ''
        }`,
    },
    { field: 'uid', headerName: 'UID', width: 200 },
  ];

  const archivedColumns: GridColDef[] = [
    {
      field: 'deletedAt',
      headerName: 'Suppression',
      width: 200,
      valueGetter: params =>
        `${params.value} ${
          params.row.deletedBy ? `(${params.row.deletedBy})` : ''
        }`,
    },
  ];

  const actionsColumn: GridColDef[] = isArchive
    ? []
    : [
        {
          field: 'actions',
          type: 'actions',
          headerName: 'Actions',
          width: 100,
          cellClassName: 'actions',
          getActions: params => {
            const { row } = params;
            return [
              <GridActionsCellItem
                icon={<EditIcon sx={{ fontSize: '1.8rem' }} />}
                label="Modifier"
                onClick={() =>
                  handleOpenEditDialog && handleOpenEditDialog(row)
                }
                color="inherit"
              />,

              <GridActionsCellItem
                icon={<DeleteIcon sx={{ fontSize: '1.8rem' }} />}
                label="Supprimer"
                onClick={() => handleDeleteLicense && handleDeleteLicense(row)}
                color="inherit"
              />,
            ];
          },
        },
      ];

  const columns = isArchive
    ? [...baseColumns, ...archivedColumns]
    : [...baseColumns, ...actionsColumn];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        id: false,
        uid: false,
        cadworkId: false,
        cadworkDisplay: false,
        code: false,
        startDate: false,
        createdAt: false,
      },
    },
  };

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (!initialized) return;

    const fetchLicensesData = async () => {
      try {
        setLoading(true);
      } catch (err: any) {
        setError({ message: err.message, open: true });
      } finally {
        setLoading(false);
      }
    };

    fetchLicensesData();
  }, [pagination, licenseChangeIndicator, searchValue, initialized, isArchive]);

  return (
    <Stack>
      <Box style={{ width: '100%' }}>
        <DataGrid
          slots={{
            toolbar: CustomToolbarWrapper,
            noRowsOverlay: () => (
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center"
                fontSize="1.5rem"
              >
                Aucune licence à afficher...
              </Stack>
            ),
          }}
          columns={columns}
          rows={licenses}
          pageSizeOptions={[10, 25, 50]}
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          checkboxSelection={!isArchive}
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={newSelection =>
            handleSelectionModelChange &&
            handleSelectionModelChange(newSelection, licenses)
          }
          keepNonExistentRowsSelected
          onRowClick={(params: GridRowParams) => {
            handleAddLicenseForDeletion &&
              handleAddLicenseForDeletion(params.row);
          }}
          getRowId={row => row.id}
          rowCount={rowCount}
          paginationMode="server"
          loading={loading}
          initialState={initialState}
        />
      </Box>
      {error.open && <SnackBar message={error.message} severity="error" />}
    </Stack>
  );
};

export default GenericLicensesTable;

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  Box,
} from '@mui/material';

interface GenericDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  disabled?: boolean;
  onConfirm?: (event: React.FormEvent) => void;
  onCancel: () => void;
  confirmButtonText: string;
  showCancel?: boolean;
}

const GenericDialog: React.FC<GenericDialogProps> = ({
  open,
  onClose,
  title,
  children,
  onConfirm,
  onCancel,
  confirmButtonText,
  disabled = false,
  showCancel = true,
}) => (
  <Dialog open={open} onClose={onClose} className="custom-dialog">
    <DialogTitle>{title}</DialogTitle>
    <Box sx={{ backgroundColor: '#121212' }}>
      <Divider sx={{ marginInline: '2.4rem', marginBlock: 0 }} />
    </Box>
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      {showCancel && <Button onClick={onCancel}>Annuler</Button>}
      <Button onClick={onConfirm} disabled={disabled}>
        {confirmButtonText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default GenericDialog;

import { useEffect, useState, useContext, useRef } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import DataContext from '../../services/DataContext';
import { License, LicenseArchive } from '../../types/licenseTypes';
import GenericLicensesTable from '../uiTools/LicensesCommon/GenericLicensesTable';
import { SnackBarProps } from '../../types/snackBarTypes';
import SnackBar from '../SnackBar/SnackBar';
import { useDebounce } from '../Hooks/useDebouce';
import { GridRowSelectionModel } from '@mui/x-data-grid';

dayjs.locale('fr');

interface LicensesTableProps {
  handleDeleteLicense: (license: License | LicenseArchive) => void;
  handleOpenAddDialog: () => void;
  handleOpenEditDialog: (license: License | LicenseArchive) => void;
  handleOpenBulkDeleteDialog?: (
    selectedLicenses: (License | LicenseArchive)[],
  ) => void;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
  licenseChangeIndicator: boolean;
  selectionModel: GridRowSelectionModel;
  handleSelectionModelChange: (
    newSelectionModel: GridRowSelectionModel,
    licenses: License[],
  ) => void;
  selectedLicensesForDeletion: (License | LicenseArchive)[];
}

const LicensesTable: React.FC<LicensesTableProps> = ({
  handleDeleteLicense,
  handleOpenAddDialog,
  handleOpenEditDialog,
  handleOpenBulkDeleteDialog,
  handleSearchChange,
  searchValue,
  licenseChangeIndicator,
  selectionModel,
  handleSelectionModelChange,
  selectedLicensesForDeletion,
}) => {
  const { getAll, getCountForPagination } = useContext(DataContext);
  const [initialized, setInitialized] = useState(false);

  const fetchLicensesDataTimeout = useRef<ReturnType<typeof setTimeout> | null>(
    null,
  );

  const lastSearchValue = useRef('');
  const debouncedSearchValue = useDebounce(searchValue, 150);

  const [snackbarInfo, setSnackbarInfo] = useState<SnackBarProps>({
    key: 0,
    message: null,
    severity: null,
  });

  const [transformedLicenses, setTransformedLicenses] = useState<License[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (!initialized) return;

    const fetchLicensesData = async () => {
      try {
        const result = await getAll(
          'licenses',
          pagination.pageSize,
          pagination.page * pagination.pageSize,
          debouncedSearchValue,
          'createdAt',
        );

        const licenses = result.data;
        const totalCount = result.totalCount;

        const transformed = licenses.map((license: License, index: number) => ({
          ...license,
          id: license._id?.toString() ?? index.toString(),
          readedAt: license.readedAt
            ? dayjs(new Date(license.readedAt)).format('DD/MM/YYYY à HH:mm')
            : undefined,
          startDate: dayjs(new Date(license.startDate)).format('DD/MM/YYYY'),
          endDate: dayjs(new Date(license.endDate)).format('DD/MM/YYYY'),
          createdAt: dayjs(new Date(license.createdAt)).format('DD/MM/YYYY'),
        }));

        const count = await getCountForPagination(
          'licenses',
          debouncedSearchValue,
        );

        setTransformedLicenses(transformed);
        setRowCount(count);
        lastSearchValue.current = debouncedSearchValue;
      } catch (error) {
        setSnackbarInfo({
          key: new Date().getTime(),
          message: `Erreur dans l'extraction des données relatives aux licences : ${error}`,
          severity: 'error',
        });
      }
    };

    if (fetchLicensesDataTimeout.current) {
      clearTimeout(fetchLicensesDataTimeout.current);
    }

    if (searchValue !== lastSearchValue.current) {
      fetchLicensesDataTimeout.current = setTimeout(fetchLicensesData, 500);
    } else {
      fetchLicensesData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initialized,
    debouncedSearchValue,
    getAll,
    getCountForPagination,
    licenseChangeIndicator,
    pagination,
  ]);

  return (
    <>
      <GenericLicensesTable
        handleDeleteLicense={handleDeleteLicense}
        handleOpenAddDialog={handleOpenAddDialog}
        handleOpenEditDialog={handleOpenEditDialog}
        handleSearchChange={handleSearchChange}
        handleOpenBulkDeleteDialog={handleOpenBulkDeleteDialog}
        searchValue={searchValue}
        licenseChangeIndicator={licenseChangeIndicator}
        isArchive={false}
        licenses={transformedLicenses}
        rowCount={rowCount}
        paginationModel={pagination}
        selectionModel={selectionModel}
        handleSelectionModelChange={handleSelectionModelChange}
        selectedLicensesForDeletion={selectedLicensesForDeletion}
        onPaginationModelChange={newPagination => {
          if (
            initialized &&
            JSON.stringify(newPagination) !== JSON.stringify(pagination)
          ) {
            setPagination(newPagination);
          }
        }}
      />

      {snackbarInfo.message && snackbarInfo.severity && (
        <SnackBar
          key={snackbarInfo.key}
          message={snackbarInfo.message}
          severity={snackbarInfo.severity}
        />
      )}
    </>
  );
};

export default LicensesTable;

import Footer from '../../Footer/Footer';
import SpeedDialNav from '../../SpeedDialNav/SpeedDialNav';
import Header from '../../Header/Header';
import { Box, CircularProgress } from '@mui/material';
import { Container } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../../services/AuthContext';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/';
  const { isAuthLoading } = useContext(AuthContext);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Header />
      <Container
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          justifyContent: isAuthLoading ? 'center' : 'flex-start',
          alignItems: isAuthLoading ? 'center' : 'stretch',
          marginBlock: isLoginPage ? '0' : '8rem',
        }}
      >
        {isAuthLoading ? <CircularProgress /> : children}
      </Container>

      <Footer />
      <SpeedDialNav />
    </Box>
  );
};

export default Layout;

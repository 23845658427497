import React, { useEffect, useRef } from 'react';
import { TextField, Button, Box } from '@mui/material';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { License, LicenseArchive } from '../../../types/licenseTypes';

interface CustomToolbarProps {
  searchValue: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOpenAddDialog?: () => void;
  selectionModel?: GridRowSelectionModel;
  selectedLicensesForDeletion?: (License | LicenseArchive)[];
  handleOpenBulkDeleteDialog?: (licenses: (License | LicenseArchive)[]) => void;
}

const CustomToolbar: React.FC<CustomToolbarProps> = ({
  searchValue,
  handleSearchChange,
  handleOpenAddDialog,
  selectionModel,
  selectedLicensesForDeletion,
  handleOpenBulkDeleteDialog,
}) => {
  const searchFieldRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (searchFieldRef.current) {
      searchFieldRef.current.focus();
    }
  }, []);

  return (
    <GridToolbarContainer>
      <TextField
        variant="standard"
        placeholder="Recherche..."
        value={searchValue}
        onChange={handleSearchChange}
        inputRef={searchFieldRef}
        sx={{
          '& input': { fontSize: '1.4rem' },
        }}
      />
      <GridToolbarColumnsButton sx={{ fontSize: '1.3rem' }} />
      <GridToolbarDensitySelector sx={{ fontSize: '1.3rem' }} />
      <GridToolbarExport sx={{ fontSize: '1.3rem' }} />
      <Box sx={{ marginLeft: 'auto' }}>
        {selectedLicensesForDeletion &&
          selectedLicensesForDeletion.length > 0 && (
            <Button
              startIcon={<DeleteIcon />}
              color="primary"
              onClick={() =>
                handleOpenBulkDeleteDialog &&
                handleOpenBulkDeleteDialog(selectedLicensesForDeletion)
              }
            >
              ({selectedLicensesForDeletion.length})
            </Button>
          )}
        {handleOpenAddDialog && (
          <Button
            onClick={handleOpenAddDialog}
            startIcon={<AddCircleOutlineSharpIcon />}
            sx={{
              fontSize: '1.4rem',
              '@media (max-width: 829px)': {
                marginLeft: '0',
              },
              '@media (min-width: 830px)': {
                marginLeft: 'auto',
              },
            }}
          >
            Ajouter une licence
          </Button>
        )}
      </Box>
    </GridToolbarContainer>
  );
};

export default CustomToolbar;

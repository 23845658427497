import React from 'react';
// import { AuthProvider } from './services/AuthContext';
import AppRouter from './services/AppRouter';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import { theme } from './styles/theme';
import { DataProvider } from './services/DataContext';
import * as locales from '@mui/material/locale';
import { AuthProvider } from './services/AuthContext';

function App() {
  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales['frFR']),
    [],
  );

  return (
    <AuthProvider>
      <DataProvider>
        <ThemeProvider theme={themeWithLocale}>
          <div className="App">
            <AppRouter />
          </div>
        </ThemeProvider>
      </DataProvider>
    </AuthProvider>
  );
}

export default App;

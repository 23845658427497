import { Alert, AlertColor } from '@mui/material';

type UserMessageProps = {
  message: string;
  severity: 'error' | 'warning' | 'info' | 'success';
};

const UserMessage: React.FC<UserMessageProps> = ({ message, severity }) => {
  const severityMapping: Record<typeof severity, AlertColor> = {
    error: 'error',
    warning: 'warning',
    info: 'info',
    success: 'success',
  };

  const alertSeverity = severityMapping[severity];

  return (
    <Alert severity={alertSeverity} className="user-message-alert">
      {message}
    </Alert>
  );
};

export default UserMessage;

import { useState, ChangeEvent } from 'react';
import { License, LicenseArchive } from '../../types/licenseTypes';
import { GridRowSelectionModel } from '@mui/x-data-grid';

const useLicenseState = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedLicense, setSelectedLicense] = useState<
    License | LicenseArchive
  >();
  const [selectedLicensesForDeletion, setSelectedLicensesForDeletion] =
    useState<(License | LicenseArchive)[]>([]);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [bulkDeleteDialogOpen, setBulkDeleteDialogOpen] =
    useState<boolean>(false);

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    [],
  );

  const [licenseChangeIndicator, setLicenseChangeIndicator] =
    useState<boolean>(false);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleDeleteLicense = (license: License | LicenseArchive) => {
    setSelectedLicense(license);
    setConfirmDialogOpen(true);
  };

  const handleOpenAddDialog = () => {
    setAddDialogOpen(true);
  };

  const handleCloseAddDialog = () => {
    setAddDialogOpen(false);
  };

  const handleOpenEditDialog = (license: License | LicenseArchive) => {
    setSelectedLicense(license);
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
  };

  const handleOpenBulkDeleteDialog = (
    selectedLicenses: (License | LicenseArchive)[],
  ) => {
    setSelectedLicensesForDeletion(selectedLicenses);
    setBulkDeleteDialogOpen(true);
  };

  const handleCloseBulkDeleteDialog = () => {
    setBulkDeleteDialogOpen(false);
  };

  const handleSelectionModelChange = (
    newSelectionModel: GridRowSelectionModel,
    licenses: License[],
  ) => {
    // Convert the selection model IDs to strings
    const newSelectionModelAsString = newSelectionModel.map(String);

    // Update the selection model
    setSelectionModel(newSelectionModelAsString);

    // Find the licenses in the current page that have been selected
    const selectedLicensesInCurrentPage = licenses.filter(license =>
      newSelectionModelAsString.includes(license.id!),
    );

    // Update the selected licenses for deletion
    setSelectedLicensesForDeletion(prevLicenses => {
      // Keep only the licenses that are still selected
      const licensesStillSelected = prevLicenses.filter(license =>
        newSelectionModelAsString.includes(license.id!),
      );

      // Add the newly selected licenses
      const newlySelectedLicenses = selectedLicensesInCurrentPage.filter(
        license => !licensesStillSelected.some(l => l.id === license.id),
      );

      return [...licensesStillSelected, ...newlySelectedLicenses];
    });
  };

  const clearSelectedLicensesForDeletion = () =>
    setSelectedLicensesForDeletion([]);

  return {
    editDialogOpen,
    handleOpenEditDialog,
    handleCloseEditDialog,
    addDialogOpen,
    confirmDialogOpen,

    handleSearchChange,
    handleDeleteLicense,
    handleOpenAddDialog,
    handleCloseAddDialog,

    searchValue,
    setSearchValue,
    setSelectedLicense,
    setConfirmDialogOpen,
    setAddDialogOpen,

    selectedLicense,
    licenseChangeIndicator,
    setLicenseChangeIndicator,

    handleSelectionModelChange,
    selectionModel,

    handleOpenBulkDeleteDialog,
    handleCloseBulkDeleteDialog,
    bulkDeleteDialogOpen,
    setBulkDeleteDialogOpen,
    selectedLicensesForDeletion,
    setSelectedLicensesForDeletion,
    clearSelectedLicensesForDeletion,

    setSelectionModel,
  };
};

export default useLicenseState;

import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import logo from '../../../assets/caddev_logo.png';
import { SnackBarProps } from '../../../types/snackBarTypes';
import SnackBar from '../../SnackBar/SnackBar';
import DataContext from '../../../services/DataContext';
import CircularProgress from '@mui/material/CircularProgress';

const ResetPassword: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const resetKey = queryParams.get('resetKey');

  const { resetPassword } = useContext(DataContext);
  const [password, setPassword] = useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const [snackbarInfo, setSnackbarInfo] = useState<SnackBarProps>({
    key: 0,
    message: null,
    severity: null,
  });

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!resetKey) {
      setSnackbarInfo({
        key: new Date().getTime(),
        message: 'La clé de réinitialisation est manquante.',
        severity: 'error',
      });

      return;
    }

    if (password !== confirmPassword) {
      setSnackbarInfo({
        key: new Date().getTime(),
        message: 'Les mots de passe ne correspondent pas.',
        severity: 'error',
      });

      return;
    }

    setIsLoading(true);

    try {
      await resetPassword({
        type: 'finish',
        token: resetKey,
        newPassword: password,
      });
      setPassword(null);
      setConfirmPassword(null);

      let timer = 5;
      const intervalId = setInterval(() => {
        timer--;
        setSuccessMessage(
          `Le mot de passe a été réinitialisé avec succès. Vous allez être redirigé dans ${timer}...`,
        );

        if (timer === 0) {
          clearInterval(intervalId);
          window.location.href = '/';
        }
      }, 1000);

      setSnackbarInfo({
        key: new Date().getTime(),
        message: '',
        severity: 'success',
      });
    } catch (error: any) {
      let errorMessage =
        "Une erreur s'est produite pendant la réinitialisation.";

      if (
        error.response.data.error.message ===
        'No associated account for the provided reset key'
      ) {
        errorMessage = 'La clé de réinitialisation fournie est incorrecte';
      } else if (
        error.response.data.error.message === 'The reset key has expired'
      ) {
        errorMessage = 'La clé de réinitialisation a expirée';
      }

      setSnackbarInfo({
        key: new Date().getTime(),
        message: errorMessage,
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      component="main"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      padding="2rem"
    >
      <Box
        maxWidth="40rem"
        width="100%"
        padding="4rem"
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          boxShadow: '0 1.5rem 2.5rem rgba(0, 0, 0, 0.6)',
          borderRadius: '1rem',
        }}
      >
        <Box>
          <Typography
            variant="h1"
            component="h1"
            sx={{
              margin: '0 0 1.6rem',
              padding: '0',
              color: 'var(--off-white-color)',
              textAlign: 'center',
            }}
          >
            Réinitialisation
            <br />
            du mot de passe
          </Typography>
        </Box>

        <Box
          component="form"
          onSubmit={handleResetPassword}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.6rem',
          }}
        >
          <TextField
            name="password"
            variant="standard"
            value={password || ''}
            label="Mot de passe"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            required
            onChange={e => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <VisibilityOff style={{ fontSize: '1.5rem' }} />
                    ) : (
                      <Visibility style={{ fontSize: '1.5rem' }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            name="confirmPassword"
            variant="standard"
            value={confirmPassword || ''}
            label="Confirmation du mot de passe"
            type={showConfirmPassword ? 'text' : 'password'}
            fullWidth
            required
            onChange={e => setConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <VisibilityOff style={{ fontSize: '1.5rem' }} />
                    ) : (
                      <Visibility style={{ fontSize: '1.5rem' }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            sx={{ marginTop: '2.5rem' }}
            type="submit"
            disabled={
              !(
                (password?.length ?? 0) >= 8 &&
                (confirmPassword?.length ?? 0) >= 8
              ) || isLoading
            }
            variant="contained"
            fullWidth
            style={{
              fontSize: '1.4rem',
              padding: '1rem',
              letterSpacing: '0.2rem',
              fontWeight: 600,
              color:
                (password?.length ?? 0) >= 8 &&
                (confirmPassword?.length ?? 0) >= 8
                  ? '#1a3c90'
                  : '',
            }}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Réinitialiser'}
          </Button>
        </Box>
      </Box>

      <Box marginTop="3rem">
        <a href="/">
          <Box
            sx={{
              filter: 'brightness(100) saturate(100%)',
            }}
          >
            <img src={logo} alt="Logo Caddev" className="logo" width="160" />
          </Box>
        </a>
      </Box>

      <SnackBar
        key={snackbarInfo.key}
        message={
          snackbarInfo.severity === 'success'
            ? successMessage
            : snackbarInfo.message
        }
        severity={snackbarInfo.severity}
      />
    </Box>
  );
};

export default ResetPassword;

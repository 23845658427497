import { useEffect, useState, useContext, useRef } from 'react';
import DataContext from '../../services/DataContext';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { LicenseArchive } from '../../types/licenseTypes';
import GenericLicensesTable from '../uiTools/LicensesCommon/GenericLicensesTable';
import { SnackBarProps } from '../../types/snackBarTypes';
import SnackBar from '../SnackBar/SnackBar';
import { useDebounce } from '../Hooks/useDebouce';

dayjs.locale('fr');

interface LicensesArchiveTableProps {
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
  licenseChangeIndicator: boolean;
}

const LicensesArchiveTable: React.FC<LicensesArchiveTableProps> = ({
  handleSearchChange,
  searchValue,
  licenseChangeIndicator,
}) => {
  const { getAll, getCountForPagination } = useContext(DataContext);
  const [initialized, setInitialized] = useState(false);

  const fetchArchivedLicensesDataTimeout = useRef<ReturnType<
    typeof setTimeout
  > | null>(null);
  const lastSearchValue = useRef('');
  const debouncedSearchValue = useDebounce(searchValue, 150);

  const [snackbarInfo, setSnackbarInfo] = useState<SnackBarProps>({
    key: 0,
    message: null,
    severity: null,
  });

  const [transformedLicenses, setTransformedLicenses] = useState<
    LicenseArchive[]
  >([]);

  const [rowCount, setRowCount] = useState<number>(0);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (!initialized) return;

    const fetchArchivedLicensesData = async () => {
      try {
        const result = await getAll(
          'licenses-archives',
          pagination.pageSize,
          pagination.page * pagination.pageSize,
          debouncedSearchValue,
          'deletedAt',
        );
        const licenses = result.data;

        const totalCount = result.totalCount;

        const transformed = licenses.map(
          (license: LicenseArchive, index: number) => ({
            ...license,

            readedAt: license.readedAt
              ? dayjs(new Date(license.readedAt)).format('DD/MM/YYYY à HH:mm')
              : undefined,
            createdAt: dayjs(new Date(license.createdAt)).format('DD/MM/YYYY'),
            deletedAt: dayjs(new Date(license.deletedAt)).format('DD/MM/YYYY'),
            startDate: dayjs(new Date(license.startDate)).format('DD/MM/YYYY'),
            endDate: dayjs(new Date(license.endDate)).format('DD/MM/YYYY'),
          }),
        );

        const count = await getCountForPagination(
          'licenses-archives',
          debouncedSearchValue,
        );

        setTransformedLicenses(transformed);
        setRowCount(count);
        lastSearchValue.current = debouncedSearchValue;
      } catch (error) {
        setSnackbarInfo({
          key: new Date().getTime(),
          message: `Erreur dans l'extraction des données relatives aux licences archivées : ${error}`,
          severity: 'error',
        });
      }
    };

    if (fetchArchivedLicensesDataTimeout.current) {
      clearTimeout(fetchArchivedLicensesDataTimeout.current);
    }

    if (searchValue !== lastSearchValue.current) {
      fetchArchivedLicensesDataTimeout.current = setTimeout(
        fetchArchivedLicensesData,
        500,
      );
    } else {
      fetchArchivedLicensesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initialized,
    debouncedSearchValue,
    getAll,
    getCountForPagination,
    licenseChangeIndicator,
    pagination,
  ]);

  useEffect(() => {
    setPagination(prevPagination => ({
      ...prevPagination,
      page: 0,
    }));
  }, [searchValue]);

  return (
    <>
      <GenericLicensesTable
        handleSearchChange={handleSearchChange}
        searchValue={searchValue}
        licenseChangeIndicator={licenseChangeIndicator}
        isArchive={true}
        licenses={transformedLicenses}
        rowCount={rowCount}
        paginationModel={pagination}
        onPaginationModelChange={newPagination => {
          if (initialized) {
            setPagination(newPagination);
          }
        }}
      />

      {snackbarInfo.message && snackbarInfo.severity && (
        <SnackBar
          key={snackbarInfo.key}
          message={snackbarInfo.message}
          severity={snackbarInfo.severity}
        />
      )}
    </>
  );
};

export default LicensesArchiveTable;

import { License } from '../../types/licenseTypes';
import { useState, useContext } from 'react';
import SnackBar from '../SnackBar/SnackBar';
import { SnackBarProps } from '../../types/snackBarTypes';
import { AuthContext } from '../../services/AuthContext';
import { getUserName } from '../../services/authUtils';
import GenericDialog from '../uiTools/Dialog/GenericDialog';

interface DeleteLicenseProps {
  open: boolean;
  onClose: () => void;
  license: License | null;
  deleteItem: (
    endpoint: string,
    identifier: string,
    email?: string,
  ) => Promise<void>;
  onLicenseChange: () => void;
}

const DeleteLicense: React.FC<DeleteLicenseProps> = ({
  open,
  onClose,
  license,
  deleteItem,
  onLicenseChange,
}) => {
  const { user } = useContext(AuthContext);
  const [snackbarInfo, setSnackbarInfo] = useState<SnackBarProps>({
    key: 0,
    message: null,
    severity: null,
  });

  const handleConfirmDelete = async () => {
    if (!license) return;
    const createdBy = getUserName(user);
    try {
      await deleteItem('licenses', license?.uid, createdBy);
      onLicenseChange();

      setSnackbarInfo({
        key: new Date().getTime(),
        message: `Licence "${license?.uid}" supprimée avec succès`,
        severity: 'success',
      });
    } catch (error) {
      setSnackbarInfo({
        key: new Date().getTime(),
        message: `Une erreur s'est produite lors de la suppression de la licence,  ${error}`,
        severity: 'error',
      });
    } finally {
      onClose();
    }
  };

  return (
    <>
      <GenericDialog
        open={open}
        onClose={onClose}
        title="Confirmation de suppression"
        onConfirm={handleConfirmDelete}
        onCancel={onClose}
        confirmButtonText="Supprimer"
      >
        <p>
          Êtes-vous sûr de vouloir supprimer cette licence ?<br />
          <small>
            uid n° <strong>{license?.uid}</strong>
          </small>
        </p>
      </GenericDialog>

      {snackbarInfo.message && snackbarInfo.severity && (
        <SnackBar
          key={snackbarInfo.key}
          message={snackbarInfo.message}
          severity={snackbarInfo.severity}
        />
      )}
    </>
  );
};

export default DeleteLicense;

import { Typography } from '@mui/material';
import './LicensesArchive.css';
import LicensesArchiveTable from './LicensesArchiveTable';
import useLicenseState from '../Hooks/useLicenseState';

export const LicensesArchive = () => {
  const { searchValue, licenseChangeIndicator, handleSearchChange } =
    useLicenseState();

  return (
    <>
      <Typography
        variant="h1"
        component="h1"
        sx={{
          color: 'var(--off-white-color)',
          textAlign: 'center',
          mb: 6,
        }}
      >
        Liste des licences archivées
      </Typography>

      <LicensesArchiveTable
        handleSearchChange={handleSearchChange}
        searchValue={searchValue}
        licenseChangeIndicator={licenseChangeIndicator}
      />
    </>
  );
};

import React, { createContext, useState, useEffect } from 'react';

export interface User {
  id: string;
  email: string;
  password?: string;
  firstName: string;
  lastName: string;
  roleIds: string[];
}

interface AuthContextProps {
  isLoggedIn: boolean;
  isAuthLoading: boolean;
  user: User | null;
  setToken: (token: string | null) => void;
  logout: () => void;
  setUser: (user: User | null) => void;
  updateUser: (updatedUser: Partial<User>) => void;
}

const AuthContext = createContext<AuthContextProps>({
  isLoggedIn: false,
  isAuthLoading: false,
  user: null,
  setToken: () => {},
  logout: () => {},
  setUser: () => {},
  updateUser: () => {},
});

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const isLoggedIn = Boolean(token);
  const [isAuthLoading, setIsAuthLoading] = useState(true);

  useEffect(() => {
    setIsAuthLoading(true);
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }

    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
    }
    setIsAuthLoading(false);
  }, []);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
  }, [token]);

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setToken(null);
    setUser(null);
  };

  const updateUser = (updatedUser: Partial<User>) => {
    const newUser = user ? { ...user, ...updatedUser } : updatedUser;

    const { password, ...userWithoutPassword } = newUser;

    setUser(newUser as User);
    localStorage.setItem('user', JSON.stringify(userWithoutPassword));
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setToken,
        logout,
        user,
        setUser,
        updateUser,
        isAuthLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };

import { FC, ReactElement } from 'react';
import { Box, Container, Typography } from '@mui/material';

export const Footer: FC = (): ReactElement => {
  return (
    <Box
      sx={{
        paddingTop: '1.6rem',
        paddingBottom: '1.6rem',
        boxShadow:
          '0px 2px 4px 5px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        color: 'text.primary',
      }}
    >
      <Container maxWidth="lg">
        <Typography textAlign="center" fontSize={'1.4rem'}>
          Caddev © {`${new Date().getFullYear()} | License manager`}
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;

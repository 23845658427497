import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../services/AuthContext';

const actions = [
  {
    icon: <MeetingRoomIcon />,
    name: 'Déconnexion',
    path: '/logout',
  },
  {
    icon: <AccountCircleIcon />,
    name: 'Profil',
    path: '/profil',
  },
];

export default function SpeedDialNav() {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  return (
    <Box sx={{ position: 'fixed', bottom: 32, right: 32, zIndex: 2000 }}>
      <SpeedDial
        ariaLabel="SpeedDial navigation"
        icon={
          <SpeedDialIcon icon={<MoreVertIcon />} openIcon={<CloseIcon />} />
        }
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => {
              if (action.name === 'Déconnexion') {
                logout();
              } else {
                navigate(action.path);
              }
            }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}

import React, { useState, useContext, useEffect } from 'react';
import {
  InputLabel,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import DataContext from '../../services/DataContext';
import { AuthContext } from '../../services/AuthContext';
import { SelectChangeEvent } from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import SnackBar from '../SnackBar/SnackBar';
import { getUserName } from '../../services/authUtils';
import { SnackBarProps } from '../../types/snackBarTypes';
import { Software } from '../../types/softwareTypes';
import GenericDialog from '../uiTools/Dialog/GenericDialog';

dayjs.locale('fr');

interface AddLicenseProps {
  open: boolean;
  onClose: () => void;
  onLicenseChange: () => void;
}

const AddLicense: React.FC<AddLicenseProps> = ({
  open,
  onClose,
  onLicenseChange,
}) => {
  const { user } = useContext(AuthContext);
  const { createLicense, getAll } = useContext(DataContext);
  const [softwareList, setSoftwareList] = useState<Software[]>([]);

  useEffect(() => {
    if (open) {
      const fetchSoftwareList = async () => {
        const result = await getAll('software', 1000, 0, '', '');
        const list = result.data;
        setSoftwareList(list);
      };

      fetchSoftwareList();
    }
  }, [getAll, open]);

  const [snackbarInfo, setSnackbarInfo] = useState<SnackBarProps>({
    key: 0,
    message: null,
    severity: null,
  });

  const initialLicenseState = {
    clientNumber: '',
    cadworkId: '',
    cadworkDisplay: '',
    softwareId: '',
    type: '',
    startDate: dayjs(new Date()),
    endDate: dayjs(new Date()),
    code: '',
  };

  const [license, setLicense] = useState(initialLicenseState);

  const handleDateChange = (name: string) => (date: dayjs.Dayjs | null) => {
    setLicense({
      ...license,
      [name]: date,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLicense({
      ...license,
      [name]: value,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const name = event.target.name as string;
    const value = event.target.value;

    setLicense({
      ...license,
      [name]: value,
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const createdBy = getUserName(user);
      const licenseToCreate = {
        ...license,
        startDate: license.startDate.toDate(),
        endDate: license.endDate.toDate(),
        createdBy,
      };

      await createLicense(licenseToCreate, createdBy);
      onLicenseChange();

      setSnackbarInfo({
        key: new Date().getTime(),
        message: 'Licence ajoutée avec succès',
        severity: 'success',
      });

      setLicense(initialLicenseState);
    } catch (error) {
      setSnackbarInfo({
        key: new Date().getTime(),
        message: `Une erreur s'est produite lors de l'ajout de la licence, ${error}`,
        severity: 'error',
      });
    } finally {
      onClose();
    }
  };

  return (
    <>
      <GenericDialog
        open={open}
        onClose={onClose}
        title="Ajouter une licence"
        onConfirm={handleSubmit}
        onCancel={onClose}
        confirmButtonText="Ajouter"
      >
        <form
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.6rem',
          }}
        >
          <TextField
            name="clientNumber"
            label="Numéro de client"
            value={license.clientNumber}
            onChange={handleChange}
            required
            fullWidth
          />

          <TextField
            name="cadworkId"
            label="ID Cadwork"
            value={license.cadworkId}
            onChange={handleChange}
            required
            fullWidth
          />

          <TextField
            name="cadworkDisplay"
            label="Cadwork Display"
            value={license.cadworkDisplay}
            onChange={handleChange}
            required
            fullWidth
          />

          <FormControl fullWidth>
            <InputLabel id="softwareId-label">Logiciel</InputLabel>
            <Select
              name="softwareId"
              id="softwareId"
              labelId="softwareId-label"
              value={license.softwareId}
              onChange={handleSelectChange}
              required
            >
              {softwareList.map(software => (
                <MenuItem key={software.id} value={software.softName}>
                  {software.softName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="type-label" style={{ backgroundColor: '#121212' }}>
              Type de licence
            </InputLabel>
            <Select
              name="type"
              id="type"
              labelId="type-label"
              value={license.type}
              onChange={handleSelectChange}
              required
            >
              <MenuItem value="temporary">Temporaire</MenuItem>
              <MenuItem value="permanent">Permanent</MenuItem>
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <DatePicker
              label="Start Date"
              value={license.startDate}
              onChange={handleDateChange('startDate')}
            />

            <DatePicker
              label="End Date"
              value={license.endDate}
              onChange={handleDateChange('endDate')}
            />
          </LocalizationProvider>

          <TextField
            name="code"
            label="Code"
            value={license.code}
            onChange={handleChange}
            required
            fullWidth
          />
        </form>
      </GenericDialog>

      {snackbarInfo.message && snackbarInfo.severity && (
        <SnackBar
          key={snackbarInfo.key}
          message={snackbarInfo.message}
          severity={snackbarInfo.severity}
        />
      )}
    </>
  );
};

export default AddLicense;

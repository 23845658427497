import { useContext, useState } from 'react';
import { License, LicenseArchive } from '../../types/licenseTypes';
import { AuthContext } from '../../services/AuthContext';
import { SnackBarProps } from '../../types/snackBarTypes';
import { getUserName } from '../../services/authUtils';
import GenericDialog from '../uiTools/Dialog/GenericDialog';
import { Box, Typography } from '@mui/material';
import SnackBar from '../SnackBar/SnackBar';

interface BulkDeleteLicensesProps {
  open: boolean;
  onClose: () => void;
  licenses: (License | LicenseArchive)[];
  deleteItem: (
    endpoint: string,
    identifier: string,
    email?: string,
  ) => Promise<void>;
  onLicenseChange: () => void;
  clearSelectedLicenses: () => void;
}

const BulkDeleteLicenses: React.FC<BulkDeleteLicensesProps> = ({
  open,
  onClose,
  licenses,
  deleteItem,
  onLicenseChange,
  clearSelectedLicenses,
}) => {
  const { user } = useContext(AuthContext);
  const [snackbarInfo, setSnackbarInfo] = useState<SnackBarProps>({
    key: 0,
    message: null,
    severity: null,
  });

  const handleConfirmDelete = async () => {
    if (!licenses) return;
    const createdBy = getUserName(user);
    try {
      for (const license of licenses) {
        await deleteItem('licenses', license.uid, createdBy);
      }
      onLicenseChange();
      clearSelectedLicenses();

      setSnackbarInfo({
        key: new Date().getTime(),
        message: `${licenses.length} licence${
          licenses.length > 1 ? 's' : ''
        } supprimée${licenses.length > 1 ? 's' : ''} avec succès`,
        severity: 'success',
      });
    } catch (error) {
      setSnackbarInfo({
        key: new Date().getTime(),
        message: `Une erreur s'est produite lors de la suppression des licences,  ${error}`,
        severity: 'error',
      });
    } finally {
      onClose();
    }
  };

  return (
    <>
      <GenericDialog
        open={open}
        onClose={onClose}
        title="Confirmation de suppression"
        onConfirm={handleConfirmDelete}
        onCancel={onClose}
        confirmButtonText="Supprimer"
      >
        <Box>
          <Typography>
            Êtes-vous sûr de vouloir supprimer{' '}
            {licenses.length > 1 ? `ces ${licenses.length}` : 'cette'} licence
            {licenses.length > 1 ? 's' : ''} ?
          </Typography>
          <Box mt={1}>
            <Typography>
              UID {licenses.length > 1 ? 'des' : 'de la'} licence
              {licenses.length > 1 ? 's' : ''} à supprimer :
              {licenses.map(license => (
                <small key={license.uid}>
                  <strong> {license.uid}</strong>
                </small>
              ))}
            </Typography>
          </Box>
        </Box>
      </GenericDialog>

      {snackbarInfo.message && snackbarInfo.severity && (
        <SnackBar
          key={snackbarInfo.key}
          message={snackbarInfo.message}
          severity={snackbarInfo.severity}
        />
      )}
    </>
  );
};

export default BulkDeleteLicenses;

import { useEffect, useState, useContext, ChangeEvent, Fragment } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, Stack, Typography } from '@mui/material';
import DataContext from '../../services/DataContext';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import CustomToolbar from '../uiTools/LicensesCommon/CustomToolbar';
import SnackBar from '../SnackBar/SnackBar';
import { SnackBarProps } from '../../types/snackBarTypes';
import { EmailHistoryTypes } from '../../types/emailHistoryTypes';
import SingleEmail from './SingleEmail';

dayjs.locale('fr');

interface CustomToolbarWrapperProps {
  searchValue: string;
  handleSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const CustomToolbarWrapper: React.FC<CustomToolbarWrapperProps> = ({
  searchValue,
  handleSearchChange,
}) => {
  return (
    <CustomToolbar
      searchValue={searchValue}
      handleSearchChange={handleSearchChange}
    />
  );
};

export const EmailHistory: React.FC = () => {
  const [searchValue, setSearchValue] = useState<string>('');

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const { getAll } = useContext(DataContext);

  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const [snackbarInfo, setSnackbarInfo] = useState<SnackBarProps>({
    key: 0,
    message: null,
    severity: null,
  });

  const [transformedEmails, setTransformedEmails] = useState<
    EmailHistoryTypes[]
  >([]);

  const [rowCount, setRowCount] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [selectedRow, setSelectedRow] = useState<EmailHistoryTypes | null>(
    null,
  );

  const handleRowClick = (param: any) => {
    setSelectedRow(param.row);
  };

  const handleClose = () => {
    setSelectedRow(null);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'sendAt', headerName: 'Date', width: 150 },
    { field: 'recipientEmail', headerName: 'Destinataire', width: 200 },
    { field: 'cwApp', headerName: 'Logiciel', width: 100 },
    { field: 'subject', headerName: 'Sujet', width: 200 },
    {
      field: 'Email Client',
      headerName: 'Email Client',
      width: 200,
      valueGetter: params => (params.row.body ? params.row.body.client : ''),
    },
    {
      field: 'Email Vendeur',
      headerName: 'Email Vendeur',
      width: 200,
      valueGetter: params => (params.row.body ? params.row.body.seller : ''),
    },
    { field: 'senderEmail', headerName: 'Email expéditeur', width: 200 },
    { field: 'triggerEvent', headerName: 'Trigger Event', width: 200 },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        id: false,
        senderEmail: false,
      },
    },
  };

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (!initialized) return;

    const fetchEmailHistoryData = async () => {
      setLoading(true);
      try {
        const result = await getAll(
          'email-license-history',
          paginationModel.pageSize,
          paginationModel.page * paginationModel.pageSize,
          searchValue,
          'sendAt',
        );
        const emails = result.data;
        const totalCount = result.totalCount;

        const transformed = emails.map(
          (email: EmailHistoryTypes, index: number) => ({
            ...email,

            sendAt: dayjs(new Date(email.sendAt)).format('DD/MM/YYYY HH:mm'),
          }),
        );

        setTransformedEmails(transformed);
        setRowCount(totalCount);
      } catch (error) {
        setSnackbarInfo({
          key: new Date().getTime(),
          message: `Erreur dans l'extraction des données relatives aux emails archivés : ${error}`,
          severity: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchEmailHistoryData();
  }, [initialized, paginationModel, searchValue, getAll]);

  return (
    <>
      <Typography
        variant="h1"
        component="h1"
        sx={{
          color: 'var(--off-white-color)',
          textAlign: 'center',
          mb: 6,
        }}
      >
        Liste des emails archivés
      </Typography>

      <Box style={{ width: '100%' }}>
        <DataGrid
          slots={{
            toolbar: () => (
              <CustomToolbarWrapper
                searchValue={searchValue}
                handleSearchChange={handleSearchChange}
              />
            ),
            noRowsOverlay: () => (
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center"
                fontSize="1.5rem"
              >
                Aucun email à afficher...
              </Stack>
            ),
          }}
          columns={columns}
          rows={transformedEmails}
          onRowClick={handleRowClick}
          pageSizeOptions={[10, 25, 50]}
          paginationModel={paginationModel}
          onPaginationModelChange={newPagination => {
            if (
              initialized &&
              (newPagination.page !== paginationModel.page ||
                newPagination.pageSize !== paginationModel.pageSize)
            ) {
              setPaginationModel(newPagination);
            }
          }}
          getRowId={row => row.id}
          rowCount={rowCount}
          paginationMode="server"
          loading={loading}
          initialState={initialState}
        />
      </Box>

      <SingleEmail selectedRow={selectedRow} handleClose={handleClose} />

      {snackbarInfo.message && snackbarInfo.severity && (
        <SnackBar
          key={snackbarInfo.key}
          message={snackbarInfo.message}
          severity={snackbarInfo.severity}
        />
      )}
    </>
  );
};

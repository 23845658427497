import { EmailHistory } from '../components/EmailHistory/EmailHistory';
import Licenses from '../components/Licenses/Licenses';
import { LicensesArchive } from '../components/LicensesArchive/LicensesArchive';

export const routes = [
  {
    name: 'Licences',
    path: '/licences',
    component: Licenses,
  },
  {
    name: 'Archives',
    path: '/archives',
    component: LicensesArchive,
  },
  {
    name: 'Emails',
    path: '/emails',
    component: EmailHistory,
  },
];

import { useContext, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { AuthContext } from './AuthContext';
import Layout from '../components/uiTools/Layout/Layout';
import { routes } from './Routes';
import NotFoundPage from '../components/uiTools/NotFoundPage/NotFoundPage';
import Profile from '../components/Profile/Profile';
import ResetPassword from '../components/Login/ResetPassword/ResetPassword';
import LoginForm from '../components/Login/LoginForm';
import CircularProgress from '@mui/material/CircularProgress';

const PageTitleUpdater = () => {
  const location = useLocation();

  const getTitle = (path: string) => {
    switch (path) {
      case '/':
        return 'Login';
      case '/licences':
        return 'Licences';
      case '/archives':
        return 'Archives';
      case '/profil':
        return 'Profil';
      case '/emails':
        return 'Emails';
      default:
        return 'Caddev';
    }
  };

  useEffect(() => {
    document.title = `${getTitle(
      location.pathname,
    )} \u2b25 Caddev Licenses Manager`;
  }, [location]);

  return null;
};

const AppRouter = () => {
  return (
    <Router>
      <PageTitleUpdater />
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="/reset-password-finish" element={<ResetPassword />} />

        <Route
          path="/profil"
          element={
            <Layout>
              <PrivateRoute component={Profile} />
            </Layout>
          }
        />

        {routes.map(({ path, component }) => (
          <Route
            key={path}
            path={path}
            element={
              <Layout>
                <PrivateRoute component={component} />
              </Layout>
            }
          />
        ))}

        <Route
          path="*"
          element={
            <Layout>
              <NotFoundPage />
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
};

const PrivateRoute: React.FC<{ component: React.ElementType }> = ({
  component: Component,
}) => {
  const { isLoggedIn, isAuthLoading } = useContext(AuthContext);

  if (isAuthLoading) {
    return <CircularProgress />;
  }

  return isLoggedIn ? <Component /> : <Navigate to="/" replace />;
};

export default AppRouter;

import React, { useState, useEffect, useContext } from 'react';
import { TextField } from '@mui/material';
import { License } from '../../types/licenseTypes';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import DataContext from '../../services/DataContext';
import { AuthContext } from '../../services/AuthContext';
import SnackBar from '../SnackBar/SnackBar';
import { SnackBarProps } from '../../types/snackBarTypes';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getUserName } from '../../services/authUtils';
import GenericDialog from '../uiTools/Dialog/GenericDialog';

dayjs.locale('fr');

interface EditLicenseProps {
  open: boolean;
  onClose: () => void;
  license: License;
  onLicenseChange: () => void;
}

const EditLicense: React.FC<EditLicenseProps> = ({
  open,
  onClose,
  license,
  onLicenseChange,
}) => {
  const { user } = useContext(AuthContext);
  const { createLicense } = useContext(DataContext);
  const [editedLicense, setEditedLicense] = useState<License | null>(null);
  const [isModified, setIsModified] = useState<boolean>(false);

  const [snackbarInfo, setSnackbarInfo] = useState<SnackBarProps>({
    key: 0,
    message: null,
    severity: null,
  });

  useEffect(() => {
    if (license) {
      const { startDate, endDate, ...otherProps } = license;

      const startDateFormatted = dayjs(startDate, 'DD/MM/YYYY')
        .set('hour', 12)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0)
        .toDate();

      const endDateFormatted = dayjs(endDate, 'DD/MM/YYYY')
        .set('hour', 12)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0)
        .toDate();

      setEditedLicense({
        ...otherProps,
        startDate: startDateFormatted,
        endDate: endDateFormatted,
      });
    }
    setIsModified(false);
  }, [license]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (editedLicense) {
      setEditedLicense(prevLicense => ({
        ...prevLicense!,
        [name]: value,
        _id: prevLicense!._id,
      }));
      setIsModified(true);
    }
  };

  const handleDateChange =
    (field: 'startDate' | 'endDate') => (newValue: dayjs.Dayjs | null) => {
      if (newValue) {
        setEditedLicense(prevLicense => ({
          ...prevLicense!,
          [field]: newValue.toDate(),
        }));
        setIsModified(true);
      }
    };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      if (!isModified || !editedLicense) {
        onClose();
        return;
      }

      const createdBy = getUserName(user);

      const { _id, id, createdAt, readedAt, ...licenseProps } = editedLicense;

      const convertedLicense = {
        ...licenseProps,
        createdBy,
      };

      await createLicense(convertedLicense, createdBy);
      onLicenseChange();

      setSnackbarInfo({
        key: new Date().getTime(),
        message: 'Licence modifiée avec succès',
        severity: 'success',
      });

      onClose();
    } catch (error) {
      setSnackbarInfo({
        key: new Date().getTime(),
        message: `Une erreur s'est produite lors de la modification de la licence : ${error}`,
        severity: 'error',
      });
    }
  };

  const handleClose = () => {
    setIsModified(false);
    if (license) {
      const { startDate, endDate, ...otherProps } = license;
      const startDateFormatted = dayjs(startDate, 'DD/MM/YYYY')
        .set('hour', 12)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0)
        .toDate();

      const endDateFormatted = dayjs(endDate, 'DD/MM/YYYY')
        .set('hour', 12)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0)
        .toDate();

      setEditedLicense({
        ...otherProps,
        startDate: startDateFormatted,
        endDate: endDateFormatted,
      });
    }
    onClose();
  };

  if (!editedLicense) return null;

  return (
    <>
      <GenericDialog
        open={open}
        onClose={handleClose}
        title="Modifier une licence"
        onConfirm={handleSubmit}
        onCancel={handleClose}
        confirmButtonText="Modifier"
        disabled={!isModified}
      >
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.6rem',
          }}
        >
          <TextField
            name="clientNumber"
            label="Numéro de client"
            value={editedLicense.clientNumber}
            onChange={handleChange}
            required
            fullWidth
            disabled
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            name="cadworkId"
            label="ID Cadwork"
            value={editedLicense.cadworkId}
            onChange={handleChange}
            required
            fullWidth
            disabled
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            name="cadworkDisplay"
            label="Cadwork Display"
            value={editedLicense.cadworkDisplay}
            onChange={handleChange}
            required
            fullWidth
            disabled
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            name="softwareId"
            label="ID Logiciel"
            value={editedLicense.softwareId}
            onChange={handleChange}
            required
            fullWidth
            disabled
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            name="type"
            label="Type"
            value={editedLicense.type}
            onChange={handleChange}
            required
            fullWidth
            disabled
            InputProps={{
              readOnly: true,
            }}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <DatePicker
              label="Date de début"
              value={dayjs(editedLicense.startDate)}
              onChange={handleDateChange('startDate')}
            />

            <DatePicker
              label="Date de fin"
              value={dayjs(editedLicense.endDate)}
              onChange={handleDateChange('endDate')}
            />
          </LocalizationProvider>

          <TextField
            name="code"
            label="Code"
            value={editedLicense.code}
            onChange={handleChange}
            required
            fullWidth
          />
        </form>
      </GenericDialog>

      {snackbarInfo.message && snackbarInfo.severity && (
        <SnackBar
          key={snackbarInfo.key}
          message={snackbarInfo.message}
          severity={snackbarInfo.severity}
        />
      )}
    </>
  );
};

export default EditLicense;

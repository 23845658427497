import React, { useState, useContext, useEffect, useRef } from 'react';
import { TextField, Button, Box, Typography, Link } from '@mui/material';
import UserMessage from '../uiTools/UserMessage/UserMessage';
import logo from '../../assets/caddev_logo.png';
import DataContext from '../../services/DataContext';
import './LoginForm.css';
import GenericDialog from '../uiTools/Dialog/GenericDialog';
import { SnackBarProps } from '../../types/snackBarTypes';
import SnackBar from '../SnackBar/SnackBar';
import CircularProgress from '@mui/material/CircularProgress';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../services/AuthContext';

const LoginForm = () => {
  const { loginUser, resetPassword } = useContext(DataContext);
  const { isLoggedIn, isAuthLoading } = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userMessage, setUserMessage] = useState({
    message: '',
    severity: 'error' as 'error' | 'warning' | 'info' | 'success',
  });

  const [isResetDialogOpen, setResetDialogOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState<string>('');

  const [snackbarInfo, setSnackbarInfo] = useState<SnackBarProps>({
    key: 0,
    message: null,
    severity: null,
  });

  const userMessageRef = useRef(userMessage);

  useEffect(() => {
    if (userMessageRef.current.message) {
      setUserMessage({
        message: '',
        severity: 'error' as 'error' | 'warning' | 'info' | 'success',
      });
    }
  }, [email, password]);

  useEffect(() => {
    userMessageRef.current = userMessage;
  }, [userMessage]);

  if (isAuthLoading) {
    return <CircularProgress />;
  }

  const isValidEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const handleResetDialogOpen = () => setResetDialogOpen(true);
  const handleResetDialogClose = () => {
    setResetEmail('');
    setIsLoading(false);
    setResetDialogOpen(false);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await loginUser(email, password);
    } catch (error: any) {
      console.error('Connection request error:', error);

      if (error.response && error.response.status === 401) {
        setUserMessage({
          message: 'Vos identifiants de connexion sont invalides.',
          severity: 'error',
        });
      } else if (!error.response || error.response.status === 503) {
        setUserMessage({
          message: 'Le serveur ne répond pas.',
          severity: 'error',
        });
      } else {
        setUserMessage({
          message: 'Une erreur inattendue est survenue.',
          severity: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/licences" />;
  }

  const handleResetPassword = async () => {
    setIsLoading(true);
    if (!isValidEmail(resetEmail)) {
      setSnackbarInfo({
        key: new Date().getTime(),
        message: 'Veuillez entrer une adresse email valide.',
        severity: 'error',
      });
      setIsLoading(false);
      return;
    }
    try {
      await resetPassword({
        type: 'init',
        email: resetEmail,
      });

      setSnackbarInfo({
        key: new Date().getTime(),
        message: `Un lien de réinitialisation a été envoyé à ${resetEmail}`,
        severity: 'success',
      });

      handleResetDialogClose();
    } catch (error) {
      setSnackbarInfo({
        key: new Date().getTime(),
        message: 'Une erreur est survenue',
        severity: 'error',
      });
      console.error(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      component="main"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      padding="2rem"
    >
      <Box
        maxWidth="40rem"
        width="100%"
        padding="4rem"
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          boxShadow: '0 1.5rem 2.5rem rgba(0, 0, 0, 0.6)',
          borderRadius: '1rem',
        }}
      >
        <Box>
          <Typography
            variant="h1"
            component="h1"
            sx={{
              margin: '0 0 1.6rem',
              padding: '0',
              color: 'var(--off-white-color)',
              textAlign: 'center',
            }}
          >
            Se connecter
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box marginBottom="2.5rem">
            <TextField
              id="email"
              label="Identifiant"
              variant="standard"
              fullWidth
              required
              value={email}
              onChange={e => setEmail(e.target.value)}
              name="email"
            />
          </Box>

          <Box marginBottom="2.5rem">
            <TextField
              id="password"
              label="Mot de passe"
              variant="standard"
              fullWidth
              required
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              name="password"
            />
          </Box>

          {userMessage.message && (
            <UserMessage
              message={userMessage.message}
              severity={userMessage.severity}
            />
          )}

          <Button
            type="submit"
            variant="contained"
            fullWidth
            style={{
              fontSize: '1.4rem',
              marginTop: '2.5rem',
              padding: '1rem',
              letterSpacing: '0.2rem',
              fontWeight: 600,
              color: '#1a3c90',
            }}
          >
            {isLoading ? (
              <CircularProgress size={24} sx={{ color: '#1a3c90' }} />
            ) : (
              'Se connecter'
            )}
          </Button>

          <Link
            component="button"
            type="button"
            onClick={handleResetDialogOpen}
            sx={{
              fontSize: '1.4rem',
              marginTop: '2rem',
              textDecoration: 'underline',
            }}
          >
            Mot de passe oublié ?
          </Link>
        </form>

        <GenericDialog
          open={isResetDialogOpen}
          onConfirm={handleResetPassword}
          onClose={handleResetDialogClose}
          title="Réinitialiser le mot de passe"
          confirmButtonText="Envoyer"
          onCancel={handleResetDialogClose}
          disabled={resetEmail === ''}
        >
          <form
            onSubmit={e => e.preventDefault()}
            onKeyDown={e => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleResetPassword();
              }
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1.6rem',
            }}
          >
            <TextField
              type="email"
              id="reset-email"
              label="Adresse e-mail"
              fullWidth
              required
              value={resetEmail}
              onChange={e => setResetEmail(e.target.value)}
            />

            {isLoading && (
              <Box display="flex" justifyContent="center">
                <CircularProgress size={30} />
              </Box>
            )}
          </form>
        </GenericDialog>
      </Box>

      <Box marginTop="3rem">
        <a href="/">
          <Box
            sx={{
              filter: 'brightness(100) saturate(100%)',
            }}
          >
            <img src={logo} alt="Logo Caddev" className="logo" width="160" />
          </Box>
        </a>
      </Box>

      {snackbarInfo.message && snackbarInfo.severity && (
        <SnackBar
          key={snackbarInfo.key}
          message={snackbarInfo.message}
          severity={snackbarInfo.severity}
        />
      )}
    </Box>
  );
};

export default LoginForm;

import { NavLink } from 'react-router-dom';
import {
  Box,
  IconButton,
  MenuItem,
  MenuList,
  useMediaQuery,
  Drawer,
  styled,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import './Nav.css';
import { routes } from '../../services/Routes';

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

interface NavProps {
  onClose: () => void;
}

const Nav: React.FC<NavProps> = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box display="flex" alignItems="center" component="nav">
      {isMobile ? (
        <>
          <CloseIconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleDrawerToggle}
          >
            {drawerOpen ? (
              <CloseIcon sx={{ fontSize: '1.5em' }} />
            ) : (
              <MenuIcon sx={{ fontSize: '1.5em' }} />
            )}
          </CloseIconButton>
        </>
      ) : (
        <MenuList
          className="menu"
          disablePadding={true}
          sx={{ display: 'flex' }}
        >
          {routes.map((route, index) => (
            <MenuItem key={index} className="menuItem" tabIndex={-1}>
              <NavLink
                to={route.path}
                style={({ isActive }) => ({
                  color: isActive ? 'var(--primary-color)' : 'white',
                })}
              >
                {({ isActive }) => (
                  <span className={isActive ? 'active' : ''}>
                    {' '}
                    {route.name.toUpperCase()}
                  </span>
                )}
              </NavLink>
            </MenuItem>
          ))}
        </MenuList>
      )}

      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
        <MenuList className="mobile-menu">
          {routes.map((route, index) => (
            <MenuItem key={index} className="menuItem" tabIndex={-1}>
              <NavLink
                to={route.path}
                style={({ isActive }) => ({
                  color: isActive ? 'var(--primary-color)' : 'white',
                })}
              >
                {({ isActive }) => (
                  <span className={isActive ? 'active' : ''}>
                    {' '}
                    {route.name.toUpperCase()}
                  </span>
                )}
              </NavLink>
            </MenuItem>
          ))}
        </MenuList>
      </Drawer>
    </Box>
  );
};

export default Nav;

import { Snackbar, Slide } from '@mui/material';
import { Alert, AlertColor } from '@mui/material';
import { useState, useEffect } from 'react';
import { SnackBarProps } from '../../types/snackBarTypes';

function SlideTransition(props: any) {
  return <Slide {...props} direction="up" />;
}

const SnackBar: React.FC<SnackBarProps> = ({ message, severity }) => {
  const [open, setOpen] = useState(!!message);

  useEffect(() => {
    setOpen(!!message);
  }, [message]);

  const onClose = () => {
    setOpen(false);
  };

  const severityMapping: Record<
    'error' | 'warning' | 'info' | 'success',
    AlertColor
  > = {
    error: 'error',
    warning: 'warning',
    info: 'info',
    success: 'success',
  };

  const alertSeverity = severity ? severityMapping[severity] : null;

  if (alertSeverity === null || message === null) return null;

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert severity={alertSeverity} onClose={onClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;

import { useEffect, useState, useContext, useCallback } from 'react';
import DataContext from '../../services/DataContext';
import { User } from '../../services/AuthContext';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import GenericDialog from '../uiTools/Dialog/GenericDialog';
import SnackBar from '../SnackBar/SnackBar';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Profile: React.FC = () => {
  const { getProfile, getItem, updateUser } = useContext(DataContext);

  const [profile, setProfile] = useState<User | null>(null);
  const [roles, setRoles] = useState<string[]>([]);

  const [email, setEmail] = useState<string | undefined>(undefined);
  const [firstName, setFirstName] = useState<string | undefined>(undefined);
  const [lastName, setLastName] = useState<string | undefined>(undefined);

  const [password, setPassword] = useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isModified, setIsModified] = useState(false);

  const [errorFields, setErrorFields] = useState<{ [key: string]: boolean }>(
    {},
  );

  const [profileVersion, setProfileVersion] = useState(0);

  const [snackBarMessage, setSnackBarMessage] = useState<string | null>(null);
  const [snackBarSeverity, setSnackBarSeverity] = useState<
    'error' | 'success' | null
  >(null);
  const [snackBarKey, setSnackBarKey] = useState(0);

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setErrorFields({});
    setPassword(null);
    setConfirmPassword(null);
    setIsModified(false);
  };

  const checkIfModified = (
    email: string | undefined,
    firstName: string | undefined,
    lastName: string | undefined,
    password: string | null,
  ) => {
    const emailModified = email !== profile?.email;
    const firstNameModified = firstName !== profile?.firstName;
    const lastNameModified = lastName !== profile?.lastName;
    const passwordModified = password !== null && password.length >= 3;

    return (
      emailModified || firstNameModified || lastNameModified || passwordModified
    );
  };

  const resetFieldError = (field: string) => {
    setErrorFields(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  useEffect(() => {
    if (profile) {
      const { password: pwd, ...userWithoutPassword } = profile;
      localStorage.setItem('user', JSON.stringify(userWithoutPassword));
    }
  }, [profile]);

  const handleUpdateProfile = async (event: React.FormEvent) => {
    event.preventDefault();

    setErrorFields({});
    let validationErrors = {};

    const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ-]+(?:\s?[A-Za-zÀ-ÖØ-öø-ÿ-]+)*$/;
    if (!nameRegex.test(firstName || '')) {
      validationErrors = { ...validationErrors, firstName: true };
    }
    if (!nameRegex.test(lastName || '')) {
      validationErrors = { ...validationErrors, lastName: true };
    }

    if (!localStorage.getItem('user')) {
      await fetchProfile();
    }

    if ((password || confirmPassword) && password !== confirmPassword) {
      setSnackBarMessage('Les mots de passe ne correspondent pas.');
      setSnackBarSeverity('error');
      setSnackBarKey(prevKey => prevKey + 1);
      validationErrors = {
        ...validationErrors,
        confirmPassword: true,
      };
    }

    if (password && password.length < 8) {
      setSnackBarMessage(
        'Le mot de passe doit comporter au moins 8 caractères.',
      );
      setSnackBarSeverity('error');
      setSnackBarKey(prevKey => prevKey + 1);
      validationErrors = { ...validationErrors, password: true };
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrorFields(validationErrors);
      return;
    }

    if (profile && profile.id) {
      try {
        const updateData: Partial<User> = {
          email,
          firstName,
          lastName,
        };

        if (password) {
          updateData.password = password;
        }

        await updateUser(profile.id, updateData as any);
        await fetchProfile();
        setProfileVersion(prevVersion => prevVersion + 1);

        setSnackBarMessage('Profil mis à jour avec succès.');
        setSnackBarSeverity('success');
        setSnackBarKey(prevKey => prevKey + 1);
      } catch (error) {
        setSnackBarMessage('Échec de la mise à jour du profil.');
        setSnackBarSeverity('error');
        setSnackBarKey(prevKey => prevKey + 1);
      }
    }

    handleCloseDialog();
  };

  const fetchProfile = useCallback(async () => {
    try {
      const userProfile = await getProfile();

      setProfile(userProfile);
      setEmail(userProfile.email);
      setFirstName(userProfile.firstName);
      setLastName(userProfile.lastName);

      const roleNames = await Promise.all(
        userProfile.roleIds.map((roleId: string) => getItem('roles', roleId)),
      );

      setRoles(roleNames.map((role: any) => role.name));

      if (!localStorage.getItem('user')) {
        const { password, ...userWithoutPassword } = userProfile;
        localStorage.setItem('user', JSON.stringify(userWithoutPassword));
      }
    } catch (error) {
      console.error('Error while fetching the profile:', error);
    }
  }, [getProfile, getItem]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile, profileVersion]);

  return (
    <Box>
      <Typography
        variant="h1"
        component="h1"
        sx={{
          color: 'var(--off-white-color)',
          textAlign: 'center',
          mb: 6,
        }}
      >
        Profil {profile?.firstName ? `de ${profile.firstName}` : ''}
      </Typography>
      {profile ? (
        <Box
          sx={{
            color: 'var(--off-white-color)',
            maxWidth: '640px',
            margin: '0 auto',
            textAlign: 'center',
            fontSize: '1.8rem',
          }}
        >
          <p>
            Email : <strong>{profile.email}</strong>
          </p>
          <p>
            Prénom : <strong>{profile.firstName}</strong>
          </p>
          <p>
            Nom : <strong>{profile.lastName}</strong>
          </p>
          <p>
            Rôle : <strong>{roles.join(', ')}</strong>
          </p>

          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 4 }}
            onClick={handleOpenDialog}
          >
            Modifier son profil
          </Button>

          <GenericDialog
            open={openDialog}
            onClose={handleCloseDialog}
            title="Modifier le profil"
            onConfirm={handleUpdateProfile}
            onCancel={handleCloseDialog}
            confirmButtonText="Mettre à jour"
            disabled={!isModified}
          >
            <form
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1.6rem',
              }}
              onKeyDown={e => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleUpdateProfile(e);
                }
              }}
            >
              <TextField
                name="email"
                label="Email"
                defaultValue={profile?.email || ''}
                fullWidth
                disabled
              />

              <TextField
                name="firstName"
                label="Prénom"
                defaultValue={profile?.firstName || ''}
                onChange={e => {
                  setFirstName(e.target.value);
                  resetFieldError('firstName');
                  setIsModified(
                    checkIfModified(email, e.target.value, lastName, password),
                  );
                }}
                error={errorFields.firstName}
                helperText={
                  errorFields.firstName ? 'Le prénom est invalide' : ''
                }
                fullWidth
                inputProps={{ maxLength: 30 }}
              />

              <TextField
                name="lastName"
                label="Nom"
                defaultValue={profile?.lastName || ''}
                onChange={e => {
                  setLastName(e.target.value);
                  resetFieldError('lastName');
                  setIsModified(
                    checkIfModified(email, firstName, e.target.value, password),
                  );
                }}
                error={errorFields.lastName}
                helperText={errorFields.lastName ? 'Le nom est invalide' : ''}
                fullWidth
                inputProps={{ maxLength: 30 }}
              />

              <TextField
                name="password"
                label="Mot de passe"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                onChange={e => {
                  const newPassword = e.target.value;
                  setPassword(newPassword);
                  resetFieldError('password');
                  setIsModified(
                    checkIfModified(email, firstName, lastName, newPassword),
                  );
                }}
                error={errorFields.password}
                helperText={
                  errorFields.password
                    ? 'Erreur dans le mot de passe'
                    : 'ℹ️ Laissez vide si vous ne souhaitez pas changer votre mot de passe'
                }
                inputProps={{ maxLength: 30 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityOff style={{ fontSize: '1.5rem' }} />
                        ) : (
                          <Visibility style={{ fontSize: '1.5rem' }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {password && (
                <TextField
                  name="confirmPassword"
                  label="Confirmation du mot de passe"
                  type={showConfirmPassword ? 'text' : 'password'}
                  fullWidth
                  onChange={e => {
                    setConfirmPassword(e.target.value);
                    resetFieldError('confirmPassword');
                  }}
                  error={errorFields.confirmPassword}
                  helperText={
                    errorFields.confirmPassword
                      ? 'Les mots de passe doivent correspondre'
                      : ''
                  }
                  inputProps={{ maxLength: 30 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff style={{ fontSize: '1.5rem' }} />
                          ) : (
                            <Visibility style={{ fontSize: '1.5rem' }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </form>
          </GenericDialog>
          <SnackBar
            message={snackBarMessage}
            severity={snackBarSeverity}
            key={snackBarKey}
          />
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Typography variant="h2" sx={{ color: 'var(--off-white-color)' }}>
            Chargement du profil...
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Profile;

import './Header.css';
import { AppBar, Box, Container, Link, Toolbar } from '@mui/material';
import logo from '../../assets/caddev_logo.png';
import Nav from '../Nav/Nav';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  const navigateToLicences = () => {
    navigate('/licences');
  };

  return (
    <AppBar position="static" className="header">
      <Container maxWidth="lg">
        <Toolbar>
          <Box display="flex" alignItems="center" flexGrow={1}>
            <Link
              role="button"
              tabIndex={0}
              style={{ cursor: 'pointer' }}
              onClick={navigateToLicences}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  navigateToLicences();
                }
              }}
            >
              <Box
                sx={{
                  filter: 'brightness(100) saturate(100%)',
                  display: 'flex',
                }}
              >
                <img
                  src={logo}
                  alt="Logo Caddev"
                  className="logo"
                  width="160"
                />
              </Box>
            </Link>
          </Box>
          <Nav onClose={() => {}} />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;

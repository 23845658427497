import { Typography, Box, Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const handleLicenseClick = () => {
    navigate('/licences');
  };

  const handleArchivedLicensesClick = () => {
    navigate('/archives');
  };

  return (
    <Box
      sx={{
        color: 'text.primary',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: '1',
      }}
    >
      <Typography
        variant="h1"
        component="h1"
        sx={{
          color: 'var(--off-white-color)',
          textAlign: 'center',
        }}
      >
        <strong>Erreur 404</strong>
      </Typography>
      <Typography
        variant="h2"
        component="h2"
        sx={{
          color: 'var(--off-white-color)',
          textAlign: 'center',
          mt: 2.5,
        }}
      >
        J'ai un serpent dans ma botte...
      </Typography>
      <Typography textAlign="center" sx={{ mt: 3 }}>
        Nous sommes désolés, mais la page que vous essayez de voir n'existe pas
        (ou plus)...
      </Typography>

      <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLicenseClick}
        >
          Retour aux licences
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleArchivedLicensesClick}
        >
          Retour aux licences archivées
        </Button>
      </Stack>
    </Box>
  );
};

export default NotFoundPage;
